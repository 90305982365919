<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{gridField.title}}</div>
              <div class="header-caption">[{{ gridField.count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">  <erp-button button-div="SAVE" @click.native="onClickAdd">추가</erp-button></li>
                <li class="print"><erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button></li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
                ref="grid"
                v-bind ="initGrid"
                @recordClick="onGridClick"
                @queryCellInfo="onQueryCellInfo"
                @headerCellInfo="onHeaderCellInfo"
                @actionComplete="gridComplete"
            />
          </div>
        </section>
      </article>
    </div>
    <!-- 각종팝업 -->
    <supplies-class-registration-popup
        v-if="isSuppliesClassPopupOpen"
        ref="codePopup"
        @popupClosed="closePopup"
        @popupSaved="savePopup"
    />
  </div>
</template>


<script>

import CommonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import StockSuppliesStd from "@/api/v2/StockManage/StockSuppliesStd";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import SuppliesClassRegistrationPopup from "@/views/stock-management/supplies/popup/SuppliesClassRegistrationPopup.vue";

export default {
  name: "SuppliesClassRegistration",
  mixins : [CommonMixin, MessagePopupDialogMixin],
  components: { EjsGridWrapper, ErpButton, ReportView, SuppliesClassRegistrationPopup},

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      // 추가 저장 팝업 보이기 여부
      isSuppliesClassPopupOpen : false,
      //그리드 관련
      gridField: {
        //title: this.lang("title","저장품 분류"), //"저장품 분류",
        title: "저장품 분류", //"저장품 분류",
        count : 0,
        dataSource: [],
      },
    };
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 .... 팝업이 없으면 false 리턴
    isPopupOpened() {
      return this.isSuppliesClassPopupOpen; //
    },
    //그리드 해더 세팅
    initGrid(){
      return{
         columns : [
          {field: 'treeName',     textAlign: 'left',    width: 250,  visible: true,   allowEditing : false,  type: 'string',   headerText: '분류명'  },
          {field: 'classCode',    textAlign: 'center',  width: 100,  visible: true,   allowEditing : false,  type: 'string',   headerText: '코드'  },
          {field: 'useFlag',      textAlign: 'center',  width: 80,   visible: true,   allowEditing : false,  type: 'boolean',  headerText: '사용여부', displayAsCheckBox: true, },
          {field: 'sortNo',       textAlign: 'center',  width: 80,   visible: true,   allowEditing : false,  type: 'number',   headerText: '정렬'  },
          {field: 'remarks',      textAlign: 'left',    width: 250,  visible: true,   allowEditing : false,  type: 'string',   headerText: '비고' },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: false, // 정렬
        allowFiltering: true, //필터
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false, //페이징
        pageSettings: { pageSize: 50 },
      };
    },


  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  mounted() {
    this.findGridList();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ############################# API Call(조회,저장,추가) ################################
    async findGridList(){
      this.gridField.dataSource = await StockSuppliesStd.getSuppliesClassList();
    },
    //endregion
    //region #################################### 버튼 이벤트 ####################################
  //추가 버튼
    onClickAdd(){
      this.openPopup();
    },
    //그리드 엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //팝업열기
    openPopup(){
      this.isSuppliesClassPopupOpen = true;
      this.$nextTick(  () => {
        this.$refs.codePopup.showPopup(true, null);
      });
    },
    //child call functin
    closePopup(){
      this.isSuppliesClassPopupOpen = false;
    },
    async savePopup(){
      this.isSuppliesClassPopupOpen = false;
      await this.findGridList();
    },

    // endregion
    //region ################################## 그리드 이벤트 ######################################
    onGridClick(args){
      const {column, rowData,} = args;
      if(column?.headerText === "분류명"){
        this.isSuppliesClassPopupOpen = true;
        if(rowData.parentCode != "0") {
          rowData.parentCodeName = this.gridField.dataSource.filter((it) => it.classCode === rowData.parentCode)[0].classCodeName;
        }
        this.$nextTick(() => {
          this.$refs.codePopup.showPopup(false, rowData);
        });
      }
    },

    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (['treeName',].includes(field)) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 스타일 적용
    onQueryCellInfo(args) {
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (['treeName',].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    // 그리드 조회 완료시 호출
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion
  }

};
</script>

<style scoped>

</style>