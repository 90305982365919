<template>
  <div>
    <ejs-dialog
        ref="dialog"
        header="저장품 분류"
        width="500"
        height="auto"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :cssClass="
        isNew ? $t('className.popup.productCodePopupAdd') : $t('className.popup.productCodePopupModify')"
        @close="onClickPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class = "content-body">
              <article class="body-article">
                <div class="article-left" style="width: 100%">
                  <section class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">기본 정보</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <!-- detail field -->
                              <li class="field voc-section">
                                <!-- 필수 : required -->
                                <div class="title">상위분류명</div>
                                <div class="content" >
                                  <ul class="row" style="display: flex" >
                                    <li class="item form-group input" >
                                      <div class="form">
                                        <input-text id="parentCodeName" ref="parentCodeName" v-model="popupInputField.parentCodeName" :readonly="true"/>
                                      </div>
                                    </li>
                                    <li class="item form-group button">
                                      <ul class="button">
                                        <li class="search">
                                          <erp-button button-div="GET" @click.native="onClickStockTreePopupShow">검색</erp-button>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field voc-section">
                                <!-- 필수 : required -->
                                <div class="title required">분류명</div>
                                <div class="content" >
                                  <ul class="row"  >
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text id="classCodeName" ref="classCodeName" v-model="popupInputField.classCodeName"/>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>

                              <li class="field voc-section">
                                <div class="title">
                                  코드
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text id="classCode" ref="classCode" v-model="popupInputField.classCode" :readonly="true"/>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field voc-section">
                                <div class="title">
                                  정렬
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-number id="sortNo" ref="sortNo" v-model="popupInputField.sortNo" :propMaxLength=3 />
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field voc-section">
                                <div class="title">
                                  사용 여부
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <ul class="check">
                                        <li>
                                          <label><input type="checkbox" id="useFlag" ref="useFlag" v-model="popupInputField.useFlag"/><i/></label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field voc-section">
                                <div class="title">
                                  비고
                                </div>
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <div class="form">
                                        <input-text id="remarks" ref="remarks" v-model="popupInputField.remarks"/>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
            <div class="windowFooter">
              <ul class="button">
                <li class="create keyColor">
                  <erp-button button-div="SAVE" :ignore="isPopupOpened" :is-shortcut-button="true" :is-key-color="true" @click.native="onClickSave">저장</erp-button>
                </li>
                <li class="close">
                  <erp-button button-div="CLOSE" @click.native="onClickPopupClose">닫기</erp-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ejs-dialog>
    <stock-tree-popup
        v-if="isTreePopup"
        ref="treePopup"
        @popupClosed="onTreePopupClose"
        @popupConfirm="onTreePopupConfirm"
    ></stock-tree-popup>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import InputText from "@/components/common/text/InputText.vue";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { validateFormRefs } from '@/utils/formUtil';
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import StockTreePopup from "@/views/stock-management/common/StockTreePopup.vue";
import InputNumber from "@/components/common/InputNumber.vue";
import StockSuppliesStd from "@/api/v2/StockManage/StockSuppliesStd";

export default {
  name:'SuppliesClassRegistrationPopup',
  mixins : [commonMixin, MessagePopupDialogMixin],
  components:{
    InputNumber,
    StockTreePopup,
    InputText,
    ErpButton,
  },
  data(){
    return{
      isTreePopup: false,
      popupInputField : {
        classCode : null,
        classCodeName: null,
        insertDt: null,
        insertId: null,
        parentCode: "0",
        remarks: null,
        sortNo: null,
        updateDt: null,
        updateId: null,
        useFlag: true,
      },
      //validate 필수 체크  항목
      validateRefList: {
        // classCode: { required: true }, // :disabled="!isNew"
        classCodeName: { required: true },
      },

      isNew: true,

      commonField:{
        division: commonCodesGetCommonCode("WRHOUS_DIV",false,false,"",""),
      },
      treeData:[],

    };

  },
  mounted(){
  },

  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 .... 팝업이 없으면 false 리턴
    isPopupOpened() {
      return this.isTreePopup; //
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },

  methods: {
    //필수입력체크
    validateFormRefs,
    // #####################################  버튼  ########################################
    // 팝업 저장 버튼
    async onClickSave() {
      if (!this.validate) {
        return;
      }

      if(this.isNew){
        await StockSuppliesStd.postSuppliesClassDetail(this.popupInputField);
      } else{
        await StockSuppliesStd.patchSuppliesClassDetail(this.popupInputField);
      }
      this.infoToast(this.$t('main.popupMessage.saved'));
      this.$emit('popupSaved');
    },


    //####################### 부모화면 function ###############################
    async showPopup(isNew,item){
      this.isNew = isNew;
      if(!isNew){
        this.popupInputField = item;
        this.currentLvel = item.level ; // 현제대상 레벨
      }
    },
    //팝업 닫기 버튼
    onClickPopupClose(){
      this.$emit('popupClosed');
    },
    // ######################## 자식화면 function ##################################
    //팝업 인터페이스
    onTreePopupConfirm(selectTreeView){
      this.isTreePopup = false;
      this.popupInputField.parentCodeName = selectTreeView.text;
      this.popupInputField.parentCode = selectTreeView.id;
    },
    //팝업 트리
    onClickStockTreePopupShow() {
      this.isTreePopup = true;
      this.setTreeData();
    },
    onTreePopupClose() {
      this.isTreePopup = false;
    },
    // #####################################  공통  ########################################
    async setTreeData(){
      let treeField ={};
      this.treeData = await StockSuppliesStd.getSuppliesClassList({useFlag:'Y'});

      //신규추가면 2레벨분류까지 , 수정이면 현제레벨 전까지
      /*let pLevel = this.isNew ? 2 : this.currentLvel -1 ;
      this.treeData = this.treeData.filter(item => item.level <= pLevel);*/

      //자기 자신의 코드는 제외
      this.treeData = this.treeData.filter(item => item.classCode != this.popupInputField.classCode);

      this.treeData.forEach((item)=>{
        //최상위 분류 없을경우( classCode :0) 부모객체 null로 변경해야 Tree 형성됨.
        //if(item.parentCode==="0"){item.parentCode=null;}
        item.hasChild = true; //무조건 자식 있음, 실제 하위데이터 없으면 개행안됨.
        //item.expanded = true; //펼치기 여부
      });
      //최상위 분류 추가
      this.treeData.push({classCode:'0', parentCode:null, classCodeName:'저장품 분류', hasChild:true, expanded: true});
      treeField.fields = { dataSource: this.treeData, id: 'classCode', parentID: 'parentCode', text: 'classCodeName', hasChildren: 'hasChild', selected: 'isSelected'};
      this.$nextTick(() => {
        this.$refs.treePopup.initShow( "분류코드선택", treeField );
      });
    },
    // #####################################  그리드  ########################################

  }


};
</script>
<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
.voc-section {
  width: 100% !important;
}
</style>